import { Grid, GridColumn, Text, ThemeOverride, ThemeProvider } from "@modernatx/ui-kit-react";
import Head from "next/head";
import { Global } from "theme-ui";

import { RenderMetadata } from "@/components/RenderMetadata";
import { RenderContent } from "@/components/rsv/RenderContent";
import { ExperienceProvider } from "@/context/ExperienceContext";
import { ExitModalProvider } from "@/hooks/useExitModal";
import { HccTheme } from "@/profiles/themes";
import { EuaTheme } from "@/profiles/themes/eua";
import { BlockPageProps } from "@/types/Block";
import { Experience } from "@/utils/experience";
import { usePostIframeSize } from "@/utils/usePostIframeSize";

interface SpikevaxPageProps {
  content: BlockPageProps | null;
  experience: Experience | null;
}

export const SpikevaxPage: React.FC<SpikevaxPageProps> = ({ content, experience }) => {
  usePostIframeSize();

  const background = (
    <Global
      styles={{
        html: {
          backgroundColor: "white"
        }
      }}
    />
  );

  if (!experience || !content) {
    return (
      <ThemeProvider>
        {background}
        <Grid>
          <GridColumn
            sx={{
              alignItems: "center",
              display: "flex",
              height: "100vh",
              justifyContent: "center"
            }}
          >
            <Text size="lg" as="p">
              The site is not available in your country.
            </Text>
          </GridColumn>
        </Grid>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider
      theme={
        content.theme === "eua"
          ? EuaTheme
          : {
              ...HccTheme,
              fonts: {
                ...HccTheme.fonts,
                headlineLevel1: "Knockout"
              },
              colorsLight: {
                ...HccTheme.colorsLight,
                accent01: "#005E83",
                accent02: "#F2FAFD",
                accent03: "#005E83",
                background02: "#D3E3EA",
                background03: "#B9D3DC",
                backgroundNeutral02: "#EAEBEF",
                backgroundNeutral04: "#9499A8",
                buttonBackgroundColorPrimary: "#005E83",
                buttonBorderColorPrimary: "#005E83",
                buttonActiveBackgroundColorPrimary: "#005E83",
                buttonHoverBackgroundColorPrimary: "#005E83",
                buttonBorderColorSecondary: "#D1D2D5",
                link01: "#016ca0",
                stroke01: "#383D47",
                stroke02: "#D1D2D5",
                tableBodyEvenBackgroundColor: "#F9F9FA",
                tableBodyOddBackgroundColor: "#FFF"
              },
              colorsDark: {
                ...HccTheme.colorsDark,
                accent01: "#ADDCF2",
                background02: "#005E83",
                background03: "#FFF",
                backgroundNeutral03: "#1e2845",
                tableHeaderBackgroundColor: "#01283C",
                tableBodyEvenBackgroundColor: "#F9F9FA",
                tableBodyOddBackgroundColor: "#FFF"
              },
              borders: {
                ...HccTheme.borders,
                tableHeaderBorder: "0.5px solid #D8D8D8",
                tableBodyBorder: "0.5px solid #E1E1E1"
              }
            }
      }
    >
      <ExperienceProvider experience={experience}>
        <Head>
          <style
            dangerouslySetInnerHTML={{
              __html: `@font-face {
              font-family: "Knockout";
              font-display: block;
              font-style: normal;
              font-weight: 400;
              src: url(https://static.modernatx.com/fonts/Knockout-Medium.woff2) format("woff2");
            }`
            }}
          />
        </Head>
        <ThemeOverride mode={content.themeMode}>
          <Global
            styles={{
              html: {
                backgroundColor: content.backgroundColor as string
              }
            }}
          />
        </ThemeOverride>
        <ExitModalProvider exitModal={content.exitModal}>
          <RenderMetadata {...content.metadata} renderGoogleRecaptcha={true} />
          <RenderContent content={content} />
        </ExitModalProvider>
      </ExperienceProvider>
    </ThemeProvider>
  );
};
